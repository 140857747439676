export const domain = location.hostname.split(".").filter((e) => e !== "www");
export const domainPreFix = domain[0];
export const domainSuffix = domain[1];
export const isLive = domain.includes("integratezone") ? 1 : 0;

export const liveAPI =
  "https://apivps.integratezone.com/Gateway/seekat/Ws1.cfc?method=";
export const qaAPI =
  "https://qaapivps.integratezone.com/gateway/seekat/ws1.cfc?method=";
export const apiURLSK = isLive ? liveAPI : qaAPI;

export const imgPlaceholderURL =
  "https://imagedelivery.net/dQyjJy4srsc15Aunpzks4A/c84d358f-2da5-4c6b-f9cb-070a45aa7800/public";
export const displayLog = isLive ? 0 : 1;

// LMS =====
export const LMSiFrameLink = "https://lms.integratezone.com/integratezone";
export const jvrLmsCfEndpoint = "https://apivps.integratezone.com/gateway/integrate/jvrlms.cfc?method=getJvrLmsData";
export const jvrLmsCfEndpointCompleteCourse = "https://apivps.integratezone.com/gateway/integrate/jvrlms.cfc?method=jvrLmsCompleteCourse";
export const jvrLmsEndpoint = "https://apivps.integratezone.com/gateway/integrate/jvrlms.cfc?method=";
export const apiURLDFlive = "https://lms.integratezone.com/gateway/WS1.cfc?method=";
export const apiURLDFQA = "https://lms.integratezone.com/QAgateway/WS1.cfc?method=";
export const apiURLDF = isLive ? apiURLDFlive : apiURLDFQA;

//----------------------------
// QA Config
export const qaPrefixes = {
  127: "../",
  localhost: "../",
  ux2: "https://integratezoneqa.com/",
  discovery: "https://discovery.integratezoneqa.com/",
  angloamericananddebeersgroup:
    "https://angloamericananddebeersgroup.integratezoneqa.com/",
  vitality: "https://vitality.integratezoneqa.com/",
};

//----------------------------
// LiVE Config
export const livePrefixes = {
  ux2: "https://integratezone.com/",
  integratezone: "https://integratezone.com/",
  admin: "https://admin.integratezone.com/",
  discovery: "https://discovery.integratezone.com/",
  angloamericananddebeersgroup:
    "https://angloamericananddebeersgroup.integratezone.com/",
  vitality: "https://vitality.integratezone.com/",
  aga: "https://aga.integratezone.com/",
  capitec: "https://capitec.integratezone.com/",
};

export const prefixes = isLive ? livePrefixes : qaPrefixes;

export const clientConfig = {
  127: 42,
  localhost: 42,
  ux2: 6,
  integratezone: 6,
  integratezoneqa: 6,
  admin: 6,
  absaigniteher: 11,
  discovery: 4,
  aga: 15,
  vitality: 18,
  capitec: 3,
  shoprite: 2,
  basrus: 12,
  angloamericananddebeersgroup: 42,
};

export const prefix = prefixes[domainPreFix]
  ? prefixes[domainPreFix]
  : isLive
  ? "https://integratezone.com/"
  : "https://integratezoneqa.com/";

export const domainClient = clientConfig[domainPreFix];

// CLIENT CUSTOMIZATION =======================
export const clientThemes = {
  localhost: {
    primaryColor: "#1d9f86",
    primaryColorOpacity: "#1d9f8534",
    secondaryColor: "#B6BB34",
    secondaryColorOpacity: "#b6bb3444",
    secondaryColorLight: "#DEE0B9",
    thirdColor: "#215257",
    fourthColour: "#739092",
    darkColour: "#132530",
  },
  integratezone: {
    primaryColor: "#1d9f86",
    primaryColorOpacity: "#1d9f8534",
    secondaryColor: "#B6BB34",
    secondaryColorOpacity: "#b6bb3444",
    secondaryColorLight: "#DEE0B9",
    thirdColor: "#215257",
    fourthColour: "#739092",
    darkColour: "#132530",
  },
  integratezoneqa: {
    primaryColor: "#1d9f86",
    primaryColorOpacity: "#1d9f8534",
    secondaryColor: "#B6BB34",
    secondaryColorOpacity: "#b6bb3444",
    secondaryColorLight: "#DEE0B9",
    thirdColor: "#215257",
    fourthColour: "#739092",
    darkColour: "#132530",
  },
  angloamericananddebeersgroup: {
    primaryColor: "#002478",
    primaryColorOpacity: "#D2DAE6",
    secondaryColor: "#fe0000",
    secondaryColorOpacity: "#fe00004d",
    secondaryColorLight: "#e8b9b9",
    thirdColor: "#4D4D4D",
    fourthColour: "#002478",
    darkColour: "#000000",
  },
  discovery: {
    primaryColor: "#114B8A",
    primaryColorOpacity: "#114b8a2d",
    secondaryColor: "#B78A61",
    secondaryColorOpacity: "#b78b616c",
    secondaryColorLight: "#ffc289",
    thirdColor: "#5fb957",
    fourthColour: "#114B8A",
    darkColour: "#000000",
  },
  aga: {
    primaryColor: "#f7941e",
    primaryColorOpacity: "#f7941e2d",
    secondaryColor: "#a3a5a8",
    secondaryColorOpacity: "#a3a5a868",
    secondaryColorLight: "#d3d3d3",
    thirdColor: "#000000",
    fourthColour: "#f7941e",
    darkColour: "#000000",
  },
  vitality: {
    primaryColor: "#f41c5e",
    primaryColorOpacity: "#f41c5d2d",
    secondaryColor: "#555555",
    secondaryColorOpacity: "#5555554b",
    secondaryColorLight: "#b1b1b1",
    thirdColor: "#56b5b3",
    fourthColour: "#f41c5e",
    darkColour: "#000000",
  },
  absaigniteher: {
    primaryColor: "#be0028",
    primaryColorOpacity: "#be00292d",
    secondaryColor: "#f47721",
    secondaryColorOpacity: "#f4752167",
    secondaryColorLight: "#e29461",
    thirdColor: "#870A3C",
    fourthColour: "#AF144B",
    darkColour: "#000000",
  },
  capitec: {
    primaryColor: "#009de0",
    primaryColorOpacity: "#009de02a",
    secondaryColor: "#e63934",
    secondaryColorOpacity: "#e63a3442",
    secondaryColorLight: "#d86a66",
    thirdColor: "#4e6066",
    fourthColour: "#00466e",
    darkColour: "#4e6066",
  },
  shoprite: {
    primaryColor: "#ea212d",
    primaryColorOpacity: "#ea212e2c",
    secondaryColor: "#526065",
    secondaryColorOpacity: "#52606565",
    secondaryColorLight: "#92acb5",
    thirdColor: "#FEEF35",
    fourthColour: "#fff9b1",
    darkColour: "#000000",
  },
};

export const clientImages = {
  localhost: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_integrate.svg",
    clientNavLogo: "clientLogoNav_integrate.svg",
  },
  integratezoneqa: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_integrate.svg",
    clientNavLogo: "clientLogoNav_integrate.svg",
  },
  integratezone: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_integrate.svg",
    clientNavLogo: "clientLogoNav_integrate.svg",
  },
  angloamericananddebeersgroup: {
    loginBG: "loginImage_aa.png",
    clientLogo: "clientLogo_aa.svg",
    clientNavLogo: "clientLogoNav_aa.svg",
  },
  discovery: {
    loginBG: "loginImage_discovery.png",
    clientLogo: "clientLogo_discovery.svg",
    clientNavLogo: "clientLogoNav_discovery.svg",
  },
  aga: {
    loginBG: "loginImage_aga.png",
    clientLogo: "clientLogo_aga.svg",
    clientNavLogo: "clientLogoNav_aga.svg",
  },
  vitality: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_vitality.svg",
    clientNavLogo: "clientLogoNav_vitality.svg",
  },
  capitec: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_capitec.svg",
    clientNavLogo: "clientLogoNav_capitec.svg",
  },
  shoprite: {
    loginBG: "loginImage_integrate.png",
    clientLogo: "clientLogo_shoprite.svg",
    clientNavLogo: "clientLogoNav_shoprite.svg",
  },
};

// Login page client logo
export const clientLogoLogin = [
  "localhost",
  "capitec",
  "ux2",
  "vitality",
  "aga",
  "shoprite",
  "discovery",
  "absaigniteher",
];

// NO logo page logos
export const noLoginLogos = ["angloamericananddebeersgroup"];

// Client Navigation Logo added
export const navClientLogo = [
  "localhost",
  "capitec",
  "vitality",
  "aga",
  "shoprite",
  "discovery",
  "absaigniteher",
  "angloamericananddebeersgroup",
];

// Client Requires NO Social links
export const noSocial = ["angloamericananddebeersgroup", "vitality"];

// Extra button for oauth for SSO
export const SSOauth = ["127", "localhost", "angloamericananddebeersgroup"];

// event listeners not to be replaced
export const notTouchyELs = [
  "saIDVal",
  "capsLock",
  "matchPass",
  "viewPass",
  "hidePassBox",
  "hide",
  "unhide",
  "toggleHide",
  "notEmptyVal",
  "mobNumVal",
  "eAddressVal",
];
